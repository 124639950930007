.App {
  position: relative;
  min-height: 100vh;
  width: 100%;
}
#content-wrap {
  padding-bottom: 2.5rem;    /* Footer height */
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            /* Footer height */
}

.unboxed-enter #header {
  width: 600px;
  transform: translateY(-70px) rotateZ(5deg);
  transition: all .25s cubic-bezier(0.25, 0.46, 0.45, 0.94) .75s, 
        translate .25s cubic-bezier(0.32, 1.16, 0.73, 1.33),
        transform .25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.unboxed-enter #header h1 {
  opacity: 0;
}

.unboxed-enter-active #header {
  width: 100%;
  /* transform: none;  */
  transform: translateY(0) rotateZ(0);
}
.unboxed-enter-active #header h1 {
  opacity: 1;
}


.unboxed-enter #footer {
  width: 600px;
  transform: translateY(50px) rotateZ(5deg);
  transition: width .25s cubic-bezier(0.25, 0.46, 0.45, 0.94) .75s,
          transform .25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.unboxed-enter-active #footer {
  width: 100%;
  /* transform: none;  */
  transform: translateY(0) rotateZ(0);
}

.swap-enter * {
  opacity: 0;
}
.swap-enter-active * {
  opacity: 1;
  transition: opacity .3s ease-in .3s;
}
.swap-leave * {
  opacity: 1;
}
.swap-leave-active * {
  opacity: 0;
  transition: opacity .3s ease-in .3s;
}

.jumbotron {
  background: #4db6ac; /* md-teal 300 */
  color: white;
  width: 100%;
  /* margin: 0; */
  top: 0;
}