#header {
  /* height: 70px; */
}

#header h1 {
  margin: 0;
  padding: 12px 0;
  font-size: 40px;
  font-weight: normal;
  /* color: white; */
}

#header div {
  display: inline-block;
  padding: 0 0.2em;
}

#header .nav {
  width: 800px;
  display: block;
  /* text-align: center; */
  margin: 0 auto;
}
.nav ul {
  list-style: none;
  /* float: left; */
  display: inline;
  text-align: right;
}
/* .nav ul:after {
  clear: both;
  content: '';
} */
.nav li {
  
  /* float: left; */
}
#header a {  
  text-decoration: none;
  color: white;
}